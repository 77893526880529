// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWrapper__e1a {
  display: flex;
  flex-direction: column;
}

.providerLabel__f01 {
  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.providerLabel__f01 + .select__c21 {
  margin-top: calc(var(--ring-unit) / 2);
}

.select__c21 + .limitEditor__c76 {
  margin-top: calc(var(--ring-unit) * 1.5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/ai-enterprise/dialogs/ai-settings-dialog/ai-settings-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kCAAkC;EAClC,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".contentWrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.providerLabel {\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n\n.providerLabel + .select {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.select + .limitEditor {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `contentWrapper__e1a`,
	"providerLabel": `providerLabel__f01`,
	"select": `select__c21`,
	"limitEditor": `limitEditor__c76`
};
export default ___CSS_LOADER_EXPORT___;
