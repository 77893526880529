import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import SmartToolsPreview from './smart-tools-preview';
import PluginDescription from './plugin-description';
import { getDescription } from './preview-description';
import styles from './settings-preview.css';
function isToolboxSettings(settings) {
    return 'content' in settings && 'updateToolsAutomatically' in settings.content;
}
export function SettingsPreview({ settings, settingType }) {
    let content;
    if ('content' in settings) {
        if (isToolboxSettings(settings)) {
            // Need to convert ToolboxSettings to an array
            const toolboxContent = Object.entries(settings.content)
                .filter(([, value]) => value) // removes any null/undefined values
                .map(([key, value]) => ({
                ...value,
                id: key
            }))
                .sort((s1, s2) => s1.id.localeCompare(s2.id));
            content = toolboxContent;
        }
        else {
            content = settings.content;
        }
    }
    return (_jsxs("div", { className: styles.boxContainer, children: [_jsx(SmartToolsPreview, { tools: settings.tools, isLoading: false }), content && (_jsx("div", { className: styles.boxSettingsListContainer, children: content.length > 0 ? (content
                    .flatMap(setting => getDescription(settingType, setting).flatMap(description => [
                    { setting, description }
                ]))
                    .flatMap(settingWithDescription => {
                    const { setting, description } = settingWithDescription;
                    switch (settingType) {
                        case 'ide':
                        case 'vmOptions':
                        case 'toolboxSetting': {
                            return (_jsx(Text, { className: styles.boxText, children: description }, description));
                        }
                        default: {
                            return _jsx(PluginDescription, { content: setting }, description);
                        }
                    }
                })) : (_jsx(Text, { className: styles.boxText, children: "No settings applied" })) }))] }));
}
