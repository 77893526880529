// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.licenceActivationForm__d24 {
  width: 800px;
  max-width: 90vw;
  display: flex;
  gap: calc(2 * var(--ring-unit));
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/license/license-activation-page.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,+BAA+B;EAC/B,sBAAsB;AACxB","sourcesContent":[".licenceActivationForm {\n  width: 800px;\n  max-width: 90vw;\n  display: flex;\n  gap: calc(2 * var(--ring-unit));\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenceActivationForm": `licenceActivationForm__d24`
};
export default ___CSS_LOADER_EXPORT___;
