import { useMutation, useQuery } from '@tanstack/react-query';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { formatApiError } from '@api/errors';
// eslint-disable-next-line import/no-cycle
import { getLicenseActivationLink, postLicense, readLicense, synchronizeLicense } from '@api/tbe-license';
import queryClient, { DEFAULT_STALE_TIME } from '@app/queries/query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from '@app/queries/formatters/alerts';
const keys = {
    activationLink: () => ['tbe-license', 'activation-link'],
    license: () => ['tbe-license', 'license']
};
const activationLinkOptions = {
    queryKey: keys.activationLink(),
    placeholderData: '',
    staleTime: DEFAULT_STALE_TIME,
    queryFn: getLicenseActivationLink,
    meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to generate activation link'))
};
export function fetchTbeLicenseActivationLink() {
    return queryClient.fetchQuery(activationLinkOptions);
}
export function useTbeLicense() {
    return useQuery({
        queryKey: keys.license(),
        queryFn: () => readLicense(),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load active license')),
        refetchInterval: data => ((data === null || data === void 0 ? void 0 : data.status) === 'VALIDATING' ? 3000 : false),
        refetchIntervalInBackground: true
    });
}
export function useTbeLicenseMutation() {
    return useMutation({
        mutationFn: (licenseCode) => postLicense(licenseCode),
        onSuccess: async (data) => {
            await queryClient.invalidateQueries();
            queryClient.setQueryData(keys.license(), data);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Activation failed. Please double-check your activation code'), 'License is activated!')
    });
}
export function useSynchronizeLicense() {
    return useMutation({
        mutationFn: synchronizeLicense,
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to validate license with Jetbrains services, please try again later'), data => {
            if (data.error) {
                alert.error(`Failed to validate license with Jetbrains services. ${data.error}`);
                return null;
            }
            if (data.isLicenseChanged) {
                return 'License was exchanged successfuly, propagation will take few seconds';
            }
            return 'License was validated but no updates are available';
        })
    });
}
