import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Select from '@jetbrains/ring-ui/components/select/select';
import { isNil } from 'lodash';
import { AiLogo } from '@components/ai/ai-logo/ai-logo';
import { ConstituentDialog } from '../../../shared/constituent-dialog/constituent-dialog';
import { LimitAssigmentEditor } from '../../../shared/limit-assigment-editor/limit-assigment-editor';
import styles from './ai-settings-dialog.css';
const selectItem = {
    key: 'jetbrains',
    label: 'JetBrains AI'
};
export function AiSettingsDialogBase(props) {
    const { onCloseAttempt, title, isLoading, onActivate, initialLimitUsage, limitsLocked } = props;
    const [usage, setUsage] = useState(initialLimitUsage !== null && initialLimitUsage !== void 0 ? initialLimitUsage : 'unlimited');
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    const handleActivate = useCallback(() => {
        onActivate({
            provider: { jetbrains: { id: 'jetbrains' } },
            assignedUsersLimit: usage === 'unlimited' ? undefined : usage
        });
    }, [onActivate, usage]);
    const activateDisabled = isNil(usage) || (usage !== 'unlimited' && usage < 1);
    return (_jsxs(ConstituentDialog, { title: title, titleLogo: _jsx(AiLogo, {}), children: [_jsx(Content, { children: _jsxs("div", { className: styles.contentWrapper, children: [_jsx("p", { className: styles.providerLabel, children: "Provider:" }), _jsx(Select, { className: styles.select, disabled: true, data: [selectItem], selected: selectItem }), _jsx(LimitAssigmentEditor, { toggleText: "Unlimited", limitNotice: _jsxs(_Fragment, { children: ["You can control your spending by setting a limit on the number of assigned users.", _jsx("br", {}), "Users who exceed this limit will have restricted access."] }), limit: usage, readonly: limitsLocked, onLimitChange: setUsage, className: styles.limitEditor })] }) }), _jsxs(Panel, { children: [_jsx(Button, { disabled: activateDisabled, loader: isLoading, onClick: handleActivate, primary: true, children: "Apply" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
