import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFieldArray, useFormContext } from 'react-hook-form';
import add from '@jetbrains/icons/add';
import close from '@jetbrains/icons/close';
import Button from '@jetbrains/ring-ui/components/button/button';
import RingFieldsList from '@components/form/ring-fields-list';
import { getEmptyDisabledPluginField } from '../../schemas/ctors';
import { PluginsField } from './plugins-field';
import ForcedField from './forced-field';
import DescriptionField from './description-field';
import styles from './plugin-field-array.css';
export function PluginFieldArray() {
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'content'
    });
    return (_jsxs("div", { className: styles.mainContainer, children: [fields.map((plugin, index) => (_jsx("div", { className: styles.optionsContainer, children: _jsxs("div", { className: styles.pluginContainer, children: [_jsxs(RingFieldsList, { children: [_jsx(PluginsField, { pluginIndex: index }), _jsx(DescriptionField, { pluginIndex: index }), _jsx(ForcedField, { pluginIndex: index })] }), _jsx(Button, { className: fields.length === 1 ? styles.hiddenButton : styles.closeButton, icon: close, onClick: () => remove(index) })] }) }, plugin.id))), _jsx(Button, { icon: add, onClick: () => append(getEmptyDisabledPluginField()), className: styles.addPluginButton, children: "Add plugin" })] }));
}
