// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__a11 {
  display: inline-flex;
  justify-content: space-between;
  align-items: start;
  background-color: var(--ring-sidebar-background-color);
  border: 1px solid var(--ring-border-disabled-color);
  border-radius: var(--ring-border-radius);
  padding: 0 4px;
  box-sizing: border-box;
}

.containerSizeFull__d1e {
  width: 100%;
}

.containerSizeAuto__a5d {
  width: min-content;
  max-width: 100%;
}

.containerSizeS__fb4 {
  width: calc(var(--ring-unit) * 12);
}

.containerSizeM__d94 {
  width: calc(var(--ring-unit) * 30);
}

.containerSizeL__d96 {
  width: calc(var(--ring-unit) * 50);
}

.container__a11:not(.disabled__d33):hover {
  border: 1px solid var(--ring-borders-color);
}

.container__a11:not(.disabled__d33):hover svg {
  color: var(--ring-icon-hover-color);
  transition: color 0.1s ease-in-out;
}

.clickable__dbd {
  cursor: pointer;
}

.text__ebd.text__ebd {
  margin: 2px;
  max-width: calc(100% - var(--ring-unit) * 4);
  overflow: auto;
  display: flex;
  align-items: center;
  cursor: default;
}

.text__ebd.text__ebd > code {
  line-height: var(--ring-line-height-taller);
  padding: 4px;
}

.disabled__d33 .text__ebd {
  --ring-code-color: var(--ring-disabled-color);
}

.inlineText__b66.inlineText__b66 > code {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/clipboard/copied-code/copied-code.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,8BAA8B;EAC9B,kBAAkB;EAClB,sDAAsD;EACtD,mDAAmD;EACnD,wCAAwC;EACxC,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,4CAA4C;EAC5C,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,2CAA2C;EAC3C,YAAY;AACd;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  display: inline-flex;\n  justify-content: space-between;\n  align-items: start;\n  background-color: var(--ring-sidebar-background-color);\n  border: 1px solid var(--ring-border-disabled-color);\n  border-radius: var(--ring-border-radius);\n  padding: 0 4px;\n  box-sizing: border-box;\n}\n\n.containerSizeFull {\n  width: 100%;\n}\n\n.containerSizeAuto {\n  width: min-content;\n  max-width: 100%;\n}\n\n.containerSizeS {\n  width: calc(var(--ring-unit) * 12);\n}\n\n.containerSizeM {\n  width: calc(var(--ring-unit) * 30);\n}\n\n.containerSizeL {\n  width: calc(var(--ring-unit) * 50);\n}\n\n.container:not(.disabled):hover {\n  border: 1px solid var(--ring-borders-color);\n}\n\n.container:not(.disabled):hover svg {\n  color: var(--ring-icon-hover-color);\n  transition: color 0.1s ease-in-out;\n}\n\n.clickable {\n  cursor: pointer;\n}\n\n.text.text {\n  margin: 2px;\n  max-width: calc(100% - var(--ring-unit) * 4);\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  cursor: default;\n}\n\n.text.text > code {\n  line-height: var(--ring-line-height-taller);\n  padding: 4px;\n}\n\n.disabled .text {\n  --ring-code-color: var(--ring-disabled-color);\n}\n\n.inlineText.inlineText > code {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__a11`,
	"containerSizeFull": `containerSizeFull__d1e`,
	"containerSizeAuto": `containerSizeAuto__a5d`,
	"containerSizeS": `containerSizeS__fb4`,
	"containerSizeM": `containerSizeM__d94`,
	"containerSizeL": `containerSizeL__d96`,
	"disabled": `disabled__d33`,
	"clickable": `clickable__dbd`,
	"text": `text__ebd`,
	"inlineText": `inlineText__b66`
};
export default ___CSS_LOADER_EXPORT___;
