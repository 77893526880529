const YT_NEW_ISSUE_LINK = 'https://youtrack.jetbrains.com/newIssue?project=IDES';
export const AlertTimeouts = {
    DEFAULT: 5000,
    SHORT: 3000,
    LONG: 10000
};
export const ExternalLinks = {
    FEEDBACK: 'https://www.jetbrains.com/feedback/ide-services/',
    BUG_REPORT: YT_NEW_ISSUE_LINK,
    SUPPORT: 'https://jb.gg/ide-services/contact-support',
    FEEDBACK_WITH_VM_OPTIONS_TEMPLATE: `${YT_NEW_ISSUE_LINK}&summary=VM%20Options%20is%20not%20allowed&description=%2F%2F%2F%20please%20specify%20what%20JVM%20options%20you%20are%20trying%20to%20specify`,
    TOOLBOX_APP: 'https://www.jetbrains.com/toolbox-app',
    AMAZON_S3: 'https://s3.region.amazonaws.com',
    MARKDOWN_SPEC: 'https://commonmark.org/help/',
    JB_MANAGING_PLUGINS: 'https://www.jetbrains.com/help/idea/managing-plugins.html#repos',
    JB_CONFIGURE_JVM: 'https://www.jetbrains.com/help/idea/tuning-the-ide.html#configure-jvm-options',
    JB_PLUGINS: 'https://plugins.jetbrains.com',
    JB_SALES: 'mailto:ideservices@jetbrains.com',
    JB_TBE_DOCS: 'https://www.jetbrains.com/help/ide-services/2024.3/get-started.html',
    JB_USAGE_REPORT_DOCS: 'https://www.jetbrains.com/help/ide-services/2024.3/license-plans.html#usage_reports',
    JB_TBE_DOCS_HOW_TO_LOOK_AT_PROFILE_CHANGES: 'https://www.jetbrains.com/help/ide-services/2024.3/configure-profiles.html#test_profiles',
    JB_TBE_OFFLINE_MODE_DOCS: 'https://www.jetbrains.com/help/ide-services/2024.3/offline-mode.html',
    JB_TBE_CLI_DOCS: 'https://www.jetbrains.com/help/ide-services/2024.3/offline-mode.html#download_artifacts_using_tbe_cli',
    JB_TBE_DOCS_DISTRIBUTE_CONFIGURATION: 'https://www.jetbrains.com/help/ide-services/2024.3/prepare-the-server-for-developer-machine-access.html#share_machine_config',
    JB_TBE_CWM_CONFIGURATION_DOC: 'https://www.jetbrains.com/help/ide-services/2024.3/get-started.html',
    JB_MARKETPLACE: 'https://plugins.jetbrains.com/',
    JB_CWM: 'https://www.jetbrains.com/code-with-me/',
    JB_LV_ACTIVATION: 'https://jb.gg/ls-activate-ide',
    JB_ACCOUNT: ' https://account.jetbrains.com',
    TOOLBOX_APP_AUTH_URL: 'jetbrains://toolbox/enterprise/auth'
};
export const AppGlobal = {
    BASE_URL: WEBPACK_DEV_BACKEND_URL,
    BUILD_NUMBER: WEBPACK_BUILD_INFO,
    COPYRIGHT: `Copyright © 2021–${new Date().getFullYear()} JetBrains`
};
