// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxContainer__b18 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) / 2);
  padding: calc(var(--ring-unit) / 2);
  max-width: 70%;
}

.boxSettingsListContainer__b0d {
  display: flex;
  flex-direction: column;
}

.boxText__af0 {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 140%;
  font-weight: 400;
  line-height: 20px;
  white-space: pre;
  word-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/settings-preview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,mCAAmC;EACnC,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".boxContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) / 2);\n  padding: calc(var(--ring-unit) / 2);\n  max-width: 70%;\n}\n\n.boxSettingsListContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.boxText {\n  display: inline-block;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  width: 140%;\n  font-weight: 400;\n  line-height: 20px;\n  white-space: pre;\n  word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxContainer": `boxContainer__b18`,
	"boxSettingsListContainer": `boxSettingsListContainer__b0d`,
	"boxText": `boxText__af0`
};
export default ___CSS_LOADER_EXPORT___;
