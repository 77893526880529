import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Input, { Size } from '@jetbrains/ring-ui/components/input/input';
import Text from '@jetbrains/ring-ui/components/text/text';
// eslint-disable-next-line import/no-cycle
import { fetchTbeLicenseActivationLink } from '@app/queries/tbe-license';
import CopiedText from '@components/clipboard/copied-text/copied-text';
import { Collapsible } from '@components/collapsible/collapsible';
import CopiedCode from '@components/clipboard/copied-code/copied-code';
import styles from './license-activation-dialog.css';
export function LicenseActivationContent({ inputValue, inputValueOnChange }) {
    const [activationLink, setActivationLink] = useState('');
    const getActivationUrlClick = useCallback(() => {
        return fetchTbeLicenseActivationLink().then(link => {
            setActivationLink(link);
            return link;
        });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsxs(Text, { children: ["To activate the JetBrains IDE Services license for your organization:", _jsxs("ol", { children: [_jsxs("li", { children: [_jsx(CopiedText, { includeIcon: false, text: "Copy the activation link", getValue: getActivationUrlClick, className: styles.copyLink }), ' ', "and open it on a machine connected to the Internet."] }), _jsx("li", { children: "On your JetBrains Account, activate JetBrains IDE Services. You will obtain an activation code." }), _jsx("li", { children: "Paste the activation code into the text area below." })] })] }), activationLink && (_jsx(Collapsible, { title: "Link to JetBrains Account", children: _jsx(CopiedCode, { text: activationLink, softWrap: true }) }))] }), _jsx(Input, { label: "Activation code from JetBrains Account", placeholder: "Paste activation code", rows: 6, size: Size.AUTO, value: inputValue, multiline: true, inputClassName: styles.licenseTextarea, onChange: e => inputValueOnChange(e.target.value) })] }));
}
