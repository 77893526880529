import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { LicenseActivationPage } from '@components/license/license-activation-page';
import auth from '../auth/auth';
import ForbiddenPage from '../errors/forbidden-page';
export function RestrictedPage(props) {
    const { roles, children, insufficientRolesComponent, unauthorizedComponent } = props;
    if (!auth.isAuthenticated()) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, { children: unauthorizedComponent !== null && unauthorizedComponent !== void 0 ? unauthorizedComponent : _jsx(ForbiddenPage, {}) });
    }
    if (!auth.hasLicense()) {
        return _jsx(LicenseActivationPage, {});
    }
    if ((roles === null || roles === void 0 ? void 0 : roles.length) && !auth.hasRoles(roles)) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, { children: insufficientRolesComponent !== null && insufficientRolesComponent !== void 0 ? insufficientRolesComponent : _jsx(ForbiddenPage, {}) });
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return _jsx(_Fragment, { children: children });
}
